export default function OSBSMKAlt() {
  return (
    <svg
      width='600'
      height='320'
      viewBox='0 0 600 320'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M348.054 24.8499V76.4927H335.626V46.4334L324.858 64.253H320.508L309.735 46.3593V76.4927H297.311V24.8499H309.321L322.718 47.0105L336.04 24.8499H348.054Z'
        fill='white'
      />
      <path
        d='M371.442 65.4124L368.269 76.4927H355.563L371.099 24.8499H389.255L404.792 76.4927H392.02L388.846 65.4124H371.442ZM385.667 54.2579L380.212 35.1361L374.687 54.2579H385.667Z'
        fill='white'
      />
      <path d='M446.436 65.4124V76.498H412.265V24.8499H424.688V65.4124H446.436Z' fill='white' />
      <path
        d='M473.231 58.8902V76.4927H460.732V58.8902L443.126 24.8499H456.937L467.019 44.697L477.03 24.8499H490.841L473.236 58.8902H473.231ZM461.009 20.4294L457.629 11.3026L478.549 2.60986L481.93 11.7367L461.009 20.4294Z'
        fill='white'
      />
      <path
        d='M336.943 196.004V200.059C336.943 208.535 332.659 212.881 324.444 212.881H297.448V161.233H323.339C331.624 161.233 335.903 165.58 335.903 174.055V177.025C335.903 180.863 334.591 183.473 331.14 185.792C335.353 187.751 336.943 190.79 336.943 196.01M319.61 172.176H309.876V181.303H319.61C322.506 181.303 323.475 180.435 323.475 177.608V175.94C323.475 173.113 322.506 172.176 319.61 172.176ZM324.509 195.935C324.509 193.04 323.541 192.097 320.644 192.097H309.876V201.949H320.644C323.546 201.949 324.509 201.007 324.509 198.111V195.935Z'
        fill='white'
      />
      <path
        d='M362.703 194.554H357.041V212.881H344.613V161.233H371.331C379.688 161.233 383.896 165.58 383.896 174.055V181.806C383.896 188.18 381.55 192.161 376.781 193.754L389.209 212.876H374.712L362.698 194.548L362.703 194.554ZM357.041 183.473H367.608C370.509 183.473 371.473 182.531 371.473 179.709V176.162C371.473 173.267 370.509 172.319 367.608 172.319H357.041V183.473Z'
        fill='white'
      />
      <path
        d='M438.771 161.233V212.881H426.686L409.081 182.822V212.881H396.652V161.233H408.667L426.338 191.293V161.233H438.771Z'
        fill='white'
      />
      <path
        d='M488.086 173.33V200.785C488.086 209.26 483.873 213.607 475.587 213.607H460.051C451.765 213.607 447.552 209.26 447.552 200.785V173.33C447.552 164.854 451.765 160.508 460.051 160.508H475.587C483.873 160.508 488.086 164.854 488.086 173.33ZM471.858 171.588H463.78C460.949 171.588 459.985 172.531 459.985 175.427V198.678C459.985 201.505 460.954 202.516 463.78 202.516H471.858C474.689 202.516 475.658 201.505 475.658 198.678V175.427C475.658 172.531 474.689 171.588 471.858 171.588Z'
        fill='white'
      />
      <path
        d='M309.876 104.747V114.964H329.485V126.044H309.876V145.314H297.448V93.6663H332.381V104.747H309.876Z'
        fill='white'
      />
      <path
        d='M380.419 105.763V133.218C380.419 141.693 376.206 146.04 367.92 146.04H352.384C344.098 146.04 339.885 141.693 339.885 133.218V105.763C339.885 97.2875 344.098 92.9411 352.384 92.9411H367.92C376.206 92.9411 380.419 97.2875 380.419 105.763ZM364.191 104.021H356.113C353.282 104.021 352.318 104.964 352.318 107.86V131.111C352.318 133.938 353.287 134.949 356.113 134.949H364.191C367.022 134.949 367.991 133.938 367.991 131.111V107.86C367.991 104.964 367.027 104.021 364.191 104.021Z'
        fill='white'
      />
      <path
        d='M428.008 104.747H413.996V145.314H401.567V104.747H387.549V93.6663H428.008V104.747Z'
        fill='white'
      />
      <path
        d='M474.941 128.437V132.492C474.941 140.968 470.657 145.314 462.442 145.314H435.446V93.6663H461.337C469.623 93.6663 473.902 98.0127 473.902 106.488V109.458C473.902 113.296 472.59 115.906 469.139 118.225C473.352 120.184 474.941 123.223 474.941 128.437ZM457.608 104.604H447.875V113.73H457.608C460.505 113.73 461.479 112.862 461.479 110.035V108.368C461.479 105.541 460.51 104.604 457.608 104.604ZM462.508 128.363C462.508 125.467 461.539 124.525 458.643 124.525H447.875V134.372H458.643C461.544 134.372 462.508 133.429 462.508 130.534V128.363Z'
        fill='white'
      />
      <path
        d='M495.529 134.229L492.355 145.309H479.649L495.186 93.6663H513.342L528.878 145.309H516.107L512.933 134.229H495.529ZM509.754 123.074L504.299 103.953L498.774 123.074H509.754Z'
        fill='white'
      />
      <path d='M570.523 134.229V145.314H536.351V93.6663H548.78V134.229H570.523Z' fill='white' />
      <path
        d='M258.487 3.76917H3.70375V133.44H3.69366V226.905L131.146 315.595L258.598 226.905L258.487 3.76917ZM249.041 138.734H248.971L249.011 221.511L131.166 303.514L131.146 303.53L13.2811 221.505V138.734H13.271V133.44H13.2912V13.8277H248.905L248.915 33.4578H249.041V138.734Z'
        fill='#BA2C1B'
      />
      <path
        d='M258.487 3.76917H3.70375V133.44H3.69366V226.905L131.146 315.595L258.598 226.905L258.487 3.76917ZM249.041 138.734H248.971L249.011 221.511L131.166 303.514L131.146 303.53L13.2811 221.505V138.734H13.271V133.44H13.2912V13.8277H248.905L248.915 33.4578H249.041V138.734Z'
        fill='#BA2C1B'
      />
      <path
        d='M131.166 133.44V303.514L131.146 303.53L13.2811 221.505V133.44H131.166Z'
        fill='#BA2C1B'
      />
      <path
        d='M249.041 33.458V138.734H248.971L248.965 133.44L248.945 93.0999H140.617V62.6119H248.93L248.915 33.458H249.041Z'
        fill='#BA2C1B'
      />
      <path
        d='M248.965 133.44H131.166V13.8279H140.617V33.458H248.915L248.93 62.6119H140.617V93.0999H248.945L248.965 133.44Z'
        fill='#BA2C1B'
      />
      <path d='M249.041 62.6119H140.617V93.0999H249.041V62.6119Z' fill='white' />
      <path d='M248.915 33.458H140.617V13.8279H248.905L248.915 33.458Z' fill='#BA2C1B' />
      <path d='M248.915 33.458H140.617V13.8279H248.905L248.915 33.458Z' fill='white' />
      <path d='M131.146 13.8279H13.2912V133.44H131.146V13.8279Z' fill='white' />
      <path d='M249.011 221.511L131.166 303.515V133.44H248.965L249.011 221.511Z' fill='white' />
      <path
        d='M0 229.034V128.416L0.0101019 126.844V0H262.065L262.181 229.028L131.146 320L0 229.034ZM3.49689 133.657V227.059L131.146 315.59L258.684 227.059L258.573 3.66873H3.50699V132.085L3.49689 133.657Z'
        fill='white'
      />
    </svg>
  );
}
