import { useTranslate } from '@tolgee/react';
import Container from '@amf/shared/components/layout/Container';
import IconAppStore from '@amf/shared/icons/IconAppStore';
import IconGooglePlay from '@amf/shared/icons/IconGooglePlay';
import FooterPartners from '@amf/shared/components/layout/FooterPartners';
import { PartnerFragment } from '@amf/shared/types/graphql';

type Props = {
  partners: PartnerFragment[];
};

export default function Footer({ partners }: Props) {
  const { t } = useTranslate();
  return (
    <div className='Footer'>
      <Container>
        <FooterPartners partners={partners} />
        <div className='Footer__row'>
          <div className='Footer__column'>
            <h2>{t('footer.billing.title')}</h2>
            <address>
              Malý fotbal Brno, z.s.
              <br />
              Jihlavská 7a
              <br />
              625 00 Brno
            </address>
            <p>IČ: 26642441</p>
          </div>
          <div className='Footer__column'>
            <h2>{t('footer.contact.title')}</h2>
            <address>
              Malý fotbal Brno, z.s.
              <br />
              Jihlavská 7a, 625 00 Brno
            </address>
            <p>
              <a href='mailto:info@malyfotbalbrno.cz'>info@malyfotbalbrno.cz</a>
              <br />
              <a href='tel:+420602725382'>+420 602 725 382</a>
            </p>
          </div>
          <div className='Footer__column'>
            <h2>{t('footer.social.title')}</h2>
            <p>
              <a
                href='https://www.facebook.com/profile.php?id=100063485323675'
                target='_blank'
                rel='noreferrer'
              >
                Facebook
              </a>
              <br />
              <a href='https://www.instagram.com/malyfotbalbrno/' target='_blank' rel='noreferrer'>
                Instagram
              </a>
              <br />
              <a href='https://sport.video/bsmk' target='_blank' rel='noreferrer'>
                Online zápasy
              </a>
            </p>
          </div>
        </div>
        <div className='Footer__apps'>
          <h2>{t('footer.apps.title')}</h2>
          <div className='Footer__appsGrid'>
            <a
              href='https://apps.apple.com/vn/app/mal%C3%BD-fotbal/id1665660869?uo=2'
              target='_blank'
              rel='noreferrer'
            >
              <IconAppStore />
            </a>
            <a
              href='https://play.google.com/store/apps/details?id=com.amf&pli=1'
              target='_blank'
              rel='noreferrer'
            >
              <IconGooglePlay />
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
}
